<script lang="ts" setup>
import { FetchError } from 'ofetch'
import type { Agency, JsonLdCollection } from '~/types/api'
import type { VSelectProps } from '~/components/organisms/VDialogSelect/VDialogSelect.vue'

interface VAgencySelectProps extends VSelectProps {
    valueField?: 'mrcAgencyCode' | '@id' | 'id'
}

const props = withDefaults(defineProps<VAgencySelectProps>(), {
    valueField: 'mrcAgencyCode',
    placeholder: 'choose_an_agency',
})

const emit = defineEmits(['update:modelValue'])

const { $apiFetch } = useNuxtApp()

const internalModelValue = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

async function fetchOptions() {
    const { data, error } = await useAsyncData<JsonLdCollection<Agency>>('agency_select', () => {
        return $apiFetch<JsonLdCollection<Agency>>('/agencies', {
            method: 'GET',
            query: {
                'exists[openingHoursSpecification]': true,
                'order[slug]': 'ASC',
                'properties[0]': 'mrcAgencyCode',
                'properties[1]': 'id',
                'properties[2]': 'title',
                'properties[3]': 'timezone',
                'properties[4]': 'minimumDepartureHoursDelay',
                'properties[5]': 'minimumReturnHoursDelay',
                'properties[6]': 'maximumDepartureDaysDelay',
                'properties[7]': 'maximumReturnDaysDelay',
                'properties[8]': 'openingHoursSpecification',
                'properties[9]': 'location',
            },
        })
    })

    if (error.value) {
        const errorException = new Error(
            (error.value as FetchError).data?.['hydra:description'] ||
                'Une erreur est survenue lors du chargement des données.',
        )
        useComponentError(errorException)
        throw errorException
    }

    return (
        (data.value?.['hydra:member'] as Array<Agency>)?.map((agency) => ({
            label: `${agency.title} (${agency.location?.address?.postalCode})`,
            value: agency[props.valueField].toLocaleLowerCase(),
        })) || []
    )
}
</script>

<template>
    <VDialogSelect
        :id="id"
        v-model="internalModelValue"
        :required="required"
        :error="error"
        :label="label"
        :placeholder="$t('choose_an_agency')"
        icon="pin"
        :options="fetchOptions"
    >
        <template #before-dialog-close>
            <VButton :class="$style.map" to="/agences">
                {{ $t('agencies_map_link') }}
                <template #icon>
                    <SvgIcon name="arrow-small-right" viewBox="0 0 24 24" width="24" height="24" />
                </template>
            </VButton>
        </template>
    </VDialogSelect>
</template>

<style lang="scss" module>
.map {
    margin-left: auto;
}
</style>
